var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal is-active",
      staticStyle: { "z-index": "10000 !important" },
    },
    [
      _c("div", { staticClass: "modal-background" }),
      _c("div", { staticClass: "modal-card" }, [
        _c("section", { staticClass: "modal-card-body" }, [
          _c("div", { staticClass: "is-size-5" }, [
            _vm._v(" Have you made an account before? "),
          ]),
          _c("br"),
          _c("div", [
            _c(
              "button",
              {
                staticClass: "button is-info is-small is-light",
                staticStyle: { margin: "10px" },
                on: {
                  click: function ($event) {
                    return _vm.click(false)
                  },
                },
              },
              [_vm._v(" Yes ")]
            ),
            _c(
              "button",
              {
                staticClass: "button is-warning is-small is-light",
                staticStyle: { margin: "10px" },
                on: {
                  click: function ($event) {
                    return _vm.click(true)
                  },
                },
              },
              [_vm._v(" No ")]
            ),
          ]),
        ]),
        _c("footer"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="modal is-active" style="z-index: 10000 !important">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="is-size-5">
          <!-- {{ $t('login.messages.firstTimeLogin') }} -->
          Have you made an account before?
        </div>
        <br />
        <div>
          <button class="button is-info is-small is-light" style="margin: 10px" @click="click(false)">
            <!-- {{ $t('orderEntry.messages.yes') }} -->
            Yes
          </button>
          <button class="button is-warning  is-small is-light" style="margin: 10px" @click="click(true)">
            <!-- {{ $t('orderEntry.messages.no') }} -->
            No
          </button>
        </div>
      </section>

      <footer></footer>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    click (bool) {
      this.$emit('first-time-login', bool)
      this.$emit('close-modal')
    }
  }
}
</script>
